/* src/LanguageSphere.css */
.sphere-container {
  position: relative; /* Positioning context for particles */
  width: 80%;
  max-width: 800px;
  margin: 0 auto; /* Center align */
  perspective: 1500px; /* Increase perspective for more depth */
  cursor: grab; /* Change cursor to grab */
}

.sphere-container:active {
  cursor: grabbing; /* Change cursor to grabbing when dragging */
}

.sphere {
  width: 100%; /* Full width of the container */
  height: 500px; /* Adjust height as needed */
  position: relative;
  transform-style: preserve-3d;
}

.sphere-item {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px; /* Increase size of the items */
  height: 50px; /* Increase size of the items */
  transform-style: preserve-3d;
  transform-origin: center center -250px; /* Adjust for larger sphere */
}

.sphere-item img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  padding: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Add shadow for 3D effect */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for transform and shadow */
  transform: translateZ(10px); /* Slightly bring the items forward */
}

.sphere-item img:hover {
  transform: translateZ(30px); /* Bring the items forward on hover */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5); /* Increase shadow on hover */
}

.sphere-item img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.9),
    rgba(200, 200, 200, 0.5)
  );
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.5),
    inset 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.sphere-item img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform: translateZ(-10px); /* Move the background slightly backward */
}

@media only screen and (max-width: 600px) {
  .sphere-container {
    position: relative;
    width: 100%;
    perspective: 1500px;
    transform: scale(0.7);
  }

  .sphere-item {
    width: 40px;
    height: 40px;
  }
}
