.navbar {
  background-color: var(--navy);
  color: var(--lightest-slate);
  font-family: "NTR";
  font-weight: bold;
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.362);
}

.navbar-light .navbar-brand {
  color: var(--lightest-slate) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--lightest-slate) !important;
  position: relative;
  display: inline-block; /* Ensure proper positioning of pseudo-element */
}

.navbar-light .navbar-nav .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-5%);
  width: 0;
  height: 2px;
  background-color: var(--green-bright);
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar-light .navbar-nav .nav-link:hover::before {
  width: 100%;
  left: 0;
}

@media only screen and (max-width: 600px) {
  .navbar-light .navbar-brand {
    display: none;
  }

  .navbar-light .navbar-nav.me-auto .nav-link {
    display: none;
  }
}
