#data-network {
  padding-bottom: 20px;
}

#data-network .p5Canvas {
  width: 400px !important;
  height: 100% !important;
}

@media only screen and (max-width: 600px) {
  #data-network .p5Canvas {
    margin-top: 20px !important;
    width: 250px !important;
    height: 100% !important;
  }
}
